import { PageHeader } from "antd";
import React from "react";

// displays a page header

export default function Header() {
  return (
    <a href="/">
      <div style={{position:"absolute",left:-20, top:-30}}>
        <img src="loogie.svg" width={130} height={130}/>
      </div>
      <PageHeader
        title={(<div style={{marginLeft:42}}>Loogies</div>)}
        subTitle=""
        style={{ cursor: "pointer" }}
      />
    </a>
  );
}
